import React from 'react';
import {Paper, AppBar, Toolbar, IconButton,Typography,Slide, Dialog, Container, Button, Checkbox, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel, Box, TextField, FormGroup} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from './logo.svg';
import affiliations from './affiliations.svg';
import './App.css';
import {InfoIcon, Close} from '@material-ui/icons';

const scoreProbabilities = [
  0.005258081,
  0.006800005,
  0.008790101,
  0.011355961,
  0.014659727,
  0.018906271,
  0.024352525,
  0.03131758,
  0.040192643,
  0.051449219,
  0.065642764,
  0.083407642,
  0.105437697,
  0.132445655,
  0.165094961,
  0.203901062,
  0.249107118,
  0.300551839,
  0.357561697,
  0.418908849,
  0.482870281,
  0.547397971,
  0.610370878,
  0.669866214,
  0.72437912,
  0.772941765,
  0.815131661,
  0.850993471,
  0.880914926,
  0.905495212,
  0.92543137,
  0.94143393,
  0.954172373,
  0.964245362,
  0.972168871,
  0.978375846,
  0.983222414,
  0.986997169,
  0.989931347,
  0.992208634,
  0.993973989,
  0.995341231,
  0.996399383,
  0.997217868,
  0.997850697
];

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sex : '',
      age : '',
      temp : '',
      hemoptysis:false,
      dyspnea:false,
      
      ckd:false,
      adl:'',
      dialogOpen:false,

    }
    


  }
  

  

  showResult = (e) => {
    this.setState({
      dialogOpen:true,
    })
  }

  handleClose = (e) => {
    this.setState({
      dialogOpen:false,
    })
  }

  handleChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    this.setState({
      [e.target.name] : e.target.value

    });
    
  }

  handleCheckboxChange = (e) =>{
    this.setState({
      [e.target.name] : e.target.checked
    })
  }

  render() {


    var modalDisabled = false;
    var score = 0;
    var probability = 0;

    if(this.state.sex === '' || this.state.age === '' || this.state.temp === '' || this.state.adl === '') {
      modalDisabled = true;
    } else {
      
      let age = isNaN(parseInt(this.state.age)) ? 0 : parseInt(this.state.age);
      let ageScore = age < 30 ? 0 : (age < 40 ? 1 : (age < 50 ? 3 : (age < 60 ? 4 : (age < 70 ? 6 : 7))));


      let temp = isNaN(parseInt(this.state.temp)) ? 0 : parseInt(this.state.temp);;
      let tempScore = temp < 37 ? 0 : (temp < 37.5 ? 1 : (temp < 38 ? 2 : (temp < 39 ? 3 : 4)));

      score = (parseInt(this.state.sex) === 0 ? 4 : 0) + 
                  ageScore + 
                  tempScore + 
                  (this.state.hemoptysis ? 7 : 0 )+ 
                  (this.state.dyspnea ? 7 : 0 )+ 
                  (this.state.ckd ? 6 : 0) + 
                  (parseInt(this.state.adl) === 1 ? 6 : (parseInt(this.state.adl) === 2 ? 9 : 0));


      
      if(score > 44) {
        probability = scoreProbabilities[44];

      } else {
        probability = scoreProbabilities[score];
      }

    }
    
    


    return (
      <Container className="main" maxWidth="sm">
      
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" className="barTitle">
              COVIC score
            </Typography>
            <Button color="inherit" onClick={(e) => window.open('https://bit.ly/covic_score', '_blank')}>Information</Button>
          </Toolbar>
        </AppBar> 
        
        
        {/* <img src={logo} alt="logo" className="logo"/> */}
        <Box className="spacer"></Box>

        <Box className="item">
          <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup row aria-label="gender" name="sex" value={this.state.sex} onChange={this.handleChange}>
              <FormControlLabel value="0" control={<Radio color="primary" />} label="Male" />
              <FormControlLabel value="1" control={<Radio color="primary"  />} label="Female" />
            </RadioGroup>
          </FormControl>


        </Box>
        <Box className="item">

          <TextField type="number" className="textfield" name="age" label="Age" onChange={this.handleChange} value={this.state.age}  variant="outlined"/>
          <TextField type="number" className="textfield" name="temp" label="Body Temperature (℃)" onChange={this.handleChange} value={this.state.temp}  variant="outlined"/>

        </Box>
        <Box className="item">
          <FormControl component="fieldset">
            <FormLabel component="legend">Symptoms & Signs</FormLabel>
            <FormGroup row>
              <FormControlLabel control={<Checkbox name="hemoptysis" color="primary" checked={this.state.hemoptysis} onChange={this.handleCheckboxChange} />} label="Hemoptysis" />
              <FormControlLabel control={<Checkbox name="dyspnea" color="primary" checked={this.state.dyspnea} onChange={this.handleCheckboxChange} />} label="Dyspnea" />
            </FormGroup>
          </FormControl>


        </Box>
        <Box className="item">
          <FormControl component="fieldset">
            <FormLabel component="legend">Disease History</FormLabel>
            <FormGroup row>
              
              <FormControlLabel control={<Checkbox name="ckd" color="primary" checked={this.state.ckd} onChange={this.handleCheckboxChange} />} label="Chronic kidney disease" />
            </FormGroup>
          </FormControl>


        </Box>
        <Box className="item">
          <FormControl component="fieldset">
            <FormLabel component="legend">Pre-disease Activites of Daily Living</FormLabel>
            <RadioGroup aria-label="gender" name="adl" value={this.state.adl} onChange={this.handleChange}>
              <FormControlLabel value="0" control={<Radio color="primary" />} label="Normal" />
              <FormControlLabel value="1" control={<Radio color="primary"  />} label="Partially dependent on others" />
              <FormControlLabel value="2" control={<Radio color="primary"  />} label="Totally dependent on others" />
            </RadioGroup>
          </FormControl>


        </Box>
        
        <Button variant="contained" color="primary" fullWidth disabled={modalDisabled} onClick={this.showResult}>Get prediction</Button>
        <Box align="center" className="item">
          <img src={affiliations} alt="affiliations" id="affiliations" />
        </Box>

        <Dialog open={this.state.dialogOpen} onClose={this.handleClose} TransitionComponent={Transition}>
          {/* <AppBar position="fixed">
            <Toolbar>
              <Typography variant="h6" className="barTitle">
                <b>COVIC</b> score & predictions 
              </Typography>
              <Button color="inherit" onClick={this.handleClose}>

                <Close /> 
              </Button>
            </Toolbar>
          </AppBar>  */}
          <Container maxWidth="sm">
            <Box className="item">
              <Typography variant="h5">Calculation result</Typography>
              <Paper variant="outlined" className="warning">
                <Typography variant="caption">
                  This is merely a decision support tool, and cannot be trusted to make a clinical decision without a doctor. The results are not conclusive and should not be taken over an actual doctor's advice.
                </Typography>
              </Paper>
              <Typography variant="overline">COVIC Score</Typography>
              <Typography variant="h4" color="primary">{score}</Typography>
              <Typography variant="overline">Probability of requiring ICU care</Typography>
              <Typography variant="h4" color="secondary">{(probability*100).toFixed(1)+'%'}</Typography>
              <Box className="item">
                <Button variant="outlined" fullWidth color="primary" onClick={this.handleClose}>Close</Button>

              </Box>
            </Box>

          </Container>


            


        
          
        </Dialog>
        

      </Container>

      

    )
  }

}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default App;
